.footer {
  background-color: rgb(249 253 255 / 0.3);
  padding: .5rem 0; 
  text-align: center;
  width: 100%; 
  bottom: 0!important;
}

.position-fixed {
  position: fixed;
}

.position-relative {
  position: relative;
}

.contact {
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
}

.contact-button {
  cursor: pointer;
  font-family: var(--letters-std);
  font-weight: 900;
  font-size: 25px;
  color: var(--color-light-ivory);

  background-color: var(--color-transparent-blue);
  border-radius: 50px;
  border: 2.5px solid var(--color-blue);
  /* height: 2.5rem; */
  margin-right: 1rem;


  padding: .25rem .75rem;
  transition: background-color 0.3s;
}
.contact-button:hover {
  background-color: var(--color-blue);
}
.mail-icon {
  height: 25px;
  margin-left: 4px;
  vertical-align: middle;
}

.home-icon {
  height: 25px;
  margin-left: 4px;
  vertical-align: middle;
}
