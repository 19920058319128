:root {
  /* colors */
  --color-pink: #FFC2EC;
  --color-yellow: #FFFF85;
  --color-green: #5EC42F;
  --color-blue: #47A6FF;
  --color-purple: #A796FF;
  --color-light-brown: #A47F6C;
  --color-med-brown: #806050;
  --color-dark-brown: #48342B;
  --color-med-ivory: #FFFFD6;
  --color-light-ivory: #FFFFF9;
  --color-cloud-blue: #F9FDFF;
  --color-cloud-white: #FDFEFF;

  /* transparent colors */
  --color-transparent: hsla(0, 0%, 0%, 0);
  --color-transparent-cloud-blue: rgb(249 253 255 / 0.5);
  --color-transparent-brown: rgba(164, 127, 108, 0.5);
  --color-transparent-green: rgba(94, 196, 47, 0.5);
  --color-transparent-pink: rgba(255, 194, 236, 0.5); 
  --color-transparent-yellow: rgba(255, 255, 133, 0.5);
  --color-transparent-blue: rgba(71, 166, 255, 0.5); 
  --color-transparent-purple: rgba(167, 150, 255, 0.5);       



  /* fonts */
  --circular-std: 'CircularStd', sans-serif;
  --super-std: 'SuperSchoolStd';
  --letters-std: 'LettersForLearnersStd';
  --letters-lined: 'LettersForLearnersLined';

  --font-sz-sm: calc(16px + 2vmin);
  --font-sz-md: calc(32px + 2vmin);
  --font-sz-lg: calc(56px + 2vmin);  
}

/* #region --- FONTS --- */ 
@font-face {
  font-family: 'CircularStd';
  src: url('../src/assets/fonts/circular_std/FontsFree-Net-Circular-Std-Book\ copy.ttf') 
       format('truetype');
}

@font-face {
  font-family: 'SuperSchoolStd';
  src: url('../src/assets/fonts/super-school/SuperSchool-vm47y.otf') 
       format('opentype');
}

@font-face {
  font-family: 'LettersForLearnersStd';
  src: url('../src/assets/fonts/letters_for_learners/Letters\ for\ Learners.ttf') 
       format('truetype');
}

@font-face {
  font-family: 'LettersForLearnersLined';
  src: url('../src/assets/fonts/letters_for_learners/Letters\ for\ Learners\ Lined.ttf') 
  format('truetype');
}
/* #endregion --- FONTS --- */ 

.App {
  font-family: var(--circular-std);
  color: var(--color-pink);
}

body {
  position: relative;
  background-color: #5EA2ED;
  background-image: url(./assets/images/cloudbg_flip.png);
  background-size: cover;
  min-height: 100vh;
  background-repeat: no-repeat;
}

.extras-page {
  background-color: rgb(239,250,254);
  background-image: url(./assets/images/cloudbg_dbl.png)!important;
  background-size: cover;
  min-height: 100vh;
  background-repeat: no-repeat;
}