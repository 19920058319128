.carousel-container {
  position: relative;
  width: 100%;
  height: 40vh;
}

.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.carousel-item {
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.carousel-arrow {
  font-family: var(--super-std);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.0);
  color: var(--c);
  border: none;
  outline: none;
  font-size: 10rem;
  font-weight: 900;
  padding: 0px;
  cursor: pointer;
  z-index: 1;
}

.carousel-arrow.left {
  left: 10px;
}

.carousel-arrow.right {
  right: 10px;
}

.carousel-indicators {
  position: absolute;
  bottom: -28px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  z-index: 1;
}

.carousel-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(94, 196, 47, 0.2);
  margin: 0 5px;
  cursor: pointer;
}

.carousel-indicator.active {
  background-color: var(--color-green);
}


/* #region --- MOBILE --- */
/* @media (max-width: 35em) {
  .carousel-indicators {
    display: none;
  }
} */
/* #endregion --- MOBILE --- */


/* #region --- MOBILE --- */
@media (max-width: 35em) {
  /* Adjust the height of the carousel container for mobile */
  .carousel-container {
    height: 30vh;
  }

  /* Reduce the font size of the carousel arrows for mobile */
  .carousel-arrow {
    font-size: 6rem;
  }

  /* Adjust the position of the carousel arrows for mobile */
  .carousel-arrow.left {
    left: 5px;
  }

  .carousel-arrow.right {
    right: 5px;
  }

  /* Reduce the size of the carousel indicators for mobile */
  .carousel-indicator {
    width: 8px;
    height: 8px;
    margin: 0 3px;
  }

  /* Adjust the position of the carousel indicators for mobile */
  .carousel-indicators {
    bottom: -20px;
  }

  /* Add the following styles to handle the width of the carousel on mobile */
  .carousel-container {
    width: 100vw;
    overflow-x: hidden;
  }

  .carousel {
    width: 100%;
  }

  .carousel-inner {
    width: 100%;
  }

  .carousel-item {
    width: 100%;
    object-fit: cover;
  }
}
/* #endregion --- MOBILE --- */