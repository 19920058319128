.Credits {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.credit-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.credit-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 1rem 1rem 1rem 1rem;
  padding: 1rem;
  height: fit-content;
  border-radius: 30px;
  background-color: var(--color-transparent-cloud-blue);
}

.credit-link {
  text-decoration: none;
  color: var(--color-purple);
  align-items: center;
  transition: 0.3s;
}
.credit-link:hover {
  color: var(--color-pink);
}

.credit-body {
  margin: .5rem;
  color: var(--color-light-brown);
}

.credit-header {
  margin-top: 0rem;
  margin-bottom: 1.5rem;
  padding: .5rem 1.5rem;
  width: fit-content;

  font-family: var(--super-std);
  font-size: var(--font-sz-sm);
  font-weight: 400;
  color: var(--color-light-ivory);

  border-radius: 50px;
}

.more-div {
  color: var(--color-yellow)!important;
  font-family: var(--super-std);
  font-size: 20px;
}

.plus-sign {
  vertical-align: middle;
}

.thank-you-msg-div {
  display: flex;
}

.thank-you-msg {
  height: fit-content;
  margin-top: 5rem;
}
/* #region --- COLORS --- */
.purple {
  background-color: var(--color-transparent-purple);
  border: 2px solid var(--color-purple);
  transition: background-color 0.3s;
}
.purple:hover {
  background-color: var(--color-purple);
}
.pink {
  background-color: var(--color-transparent-pink);
  border: 2px solid var(--color-pink);
  transition: background-color 0.3s;
}
.pink:hover {
  background-color: var(--color-pink);
}
.yellow {
  background-color: var(--color-transparent-yellow);
  border: 2px solid var(--color-yellow);
  transition: background-color 0.3s;
}
.yellow:hover {
  background-color: var(--color-yellow);
}
.green {
  background-color: var(--color-transparent-green);
  border: 2px solid var(--color-green);
  transition: background-color 0.3s;
}
.green:hover {
  background-color: var(--color-green);
}
.blue {
  background-color: var(--color-transparent-blue);
  border: 2px solid var(--color-blue);
  transition: background-color 0.3s;
}
.blue:hover {
  background-color: var(--color-blue);
}
.cream {
  background-color: var(--color-transparent-cloud-blue);
  border: 2px solid var(--color-light-ivory);
  color: var(--color-blue);
  transition: background-color 0.3s;
}
.cream:hover {
  background-color: var(--color-light-ivory);
}
/* #endregion --- COLORS --- */
