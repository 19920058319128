.about-page {
  text-align: center;
}
.about-flex {
  display: flex;
  gap: .5rem;
}

.about-cards-container {
  margin: 1.5rem;
}

.line-height {
  line-height: 1.5;
}

/* #region --- COLORFUL H1s STYLING --- */
.green-h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  padding: 1rem 2rem;

  font-family: var(--super-std);
  font-size: var(--font-sz-md);
  font-weight: 400;
  color: var(--color-light-ivory);

  background-color: var(--color-transparent-green);
  border: 2px solid var(--color-green);
  border-radius: 50px;

  transition: background-color 0.3s;
}
.green-h1:hover {
  background-color: var(--color-green);
}

.pink-h1 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem 2rem;

  font-family: var(--super-std);
  font-size: var(--font-sz-md);
  font-weight: 400;
  color: var(--color-light-ivory);

  background-color: var(--color-transparent-pink);
  border: 2px solid var(--color-pink);
  border-radius: 50px;

  transition: background-color 0.3s;
}
.pink-h1:hover {
  background-color: var(--color-pink);
}

/* #endregion --- HEADER STYLING --- */

.text-block {
  color: var(--color-light-brown);
  font-size: 20px;
  background-color: var(--color-transparent-cloud-blue);
  border-radius: 10px;
  padding: 1rem;
  width: 50rem;
  margin: auto;
}
.text-block-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin-bottom: 2rem;

  width: 54rem;

  background-color: var(--color-transparent-cloud-blue);
  border-radius: 50px;
  padding: 2rem;
  gap: 1.5rem;
}

/* #region --- MEDIA QUERIES --- */
@media (max-width: 35em) {
  .about-cards-container {
    flex-direction: column;
  }
  .about-page h1 {
    font-size: 35px;
    width: 16rem;
  }
  .about-flex {
    display: flex;
    gap: 1.5rem!important;
  }
  .text-block {
    width: 16rem;
    margin: 0;
    font-size: 17px;
  }
  .text-block-div {
    width: 18rem;
    gap: .75rem;
  }
}
@media (max-width: 48em) {
  .about-cards-container {
    flex-direction: column;
  }
  .about-page h1 {
    max-width: 20rem;
  }
}
@media (min-width: 570px) and (max-width: 956px) {
  .about-cards-container {
    flex-direction: column;
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto; 
  }  
  .about-page h1 {
    max-width: 22rem;
  }
  .about-flex {
    display: flex;
    gap: 2rem;
  }
  .text-block {
    width: 25rem;
  }
  .text-block-div {
    width: 28rem;
  }
}
@media (min-width: 1100px) {
  .about-cards-container {
      margin-left: 6vw; 
      margin-right: 6vw; 
  }
}
/* #endregion --- MEDIA QUERIES --- */