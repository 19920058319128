.carousel-div {
  background-color: var(--color-transparent-cloud-blue);
  padding: 3rem 4rem;
  border-radius: 50px;
  margin: 1rem 1rem;
  width: 900px
}

/* #region --- MOBILE --- */
@media (max-width: 35em) {
  .carousel-div {
    width: 100%;
    padding: 2rem 0rem;
    border-radius: 30px;
    margin: 1rem 0rem;
  }
}
/* #endregion --- MOBILE --- */