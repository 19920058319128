.back-link {
  text-decoration: none;
  color: var(--color-light-brown);
}

.game-ass-header {
  margin-top: 0rem;
  margin-bottom: 1.5rem;
  padding: .5rem 1.5rem;
  width: fit-content;

  font-family: var(--super-std);
  font-size: var(--font-sz-sm);
  font-weight: 400;
  color: var(--color-light-ivory);

  border-radius: 50px;
}

/* #region --- MEDIA QUERIES --- */

@media (max-width: 35em) {
  .game-ass-header {
    font-size: 30px!important;
  }
}

/* #endregion --- MEDIA QUERIES --- */