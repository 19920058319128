img {
  margin-bottom: .5rem;
}

.sv-og-logo {
  max-width: 50rem;
}

.img-height {
  max-height: 30rem;
}

.portrait {
  max-height: 30rem;
}

.landscape {
  max-height: 30rem;
}


.Extras {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.extra-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.extra-header {
  margin-top: 0rem;
  margin-bottom: 1rem;
  padding: .5rem 1.5rem;
  width: fit-content;

  font-family: var(--super-std);
  font-size: var(--font-sz-sm);
  font-weight: 400;
  color: var(--color-light-ivory);

  border-radius: 50px;
}

.extra-div {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 1rem 1rem 1rem 1rem;
  padding: 1rem;
  height: fit-content;
  border-radius: 30px;
  background-color: var(--color-transparent-cloud-blue);
}


/* #region --- MEDIA QUERIES --- */

@media (max-width: 35em) {
  .extra-header {
    font-size: 30px!important;
  }
  .extra-div {
    margin: .5rem 0;
  }
  .sv-og-logo {
    max-width: 23rem!important;
  }
  
  .img-height {
    max-height: 20rem!important;
  }

  .landscape {
    max-height: 18rem!important;
  }

}

/* #endregion --- MEDIA QUERIES --- */