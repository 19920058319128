.Content {
  position: relative; /* Ensure z-index works properly */
}

a {
  text-decoration: none;
}

.Landing-logo {
  height: 30vmin;
  padding-top: 10vh;
  z-index: 2;
}

.Landing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Cloud-background {
  /* position: absolute; */
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url(../assets/game_pngs/Cloud.png);
  background-repeat: no-repeat;
  background-position: center;
  top: 3rem;
  background-size: 47rem;
}

.Learn {
  font-family: var(--letters-std);
  font-weight: 600;
  color: var(--color-light-brown);
  font-size: var(--font-sz-md);
  text-align: center;
  margin: 0;
  /* z-index: 2; */
}


/* #region --- PLAY BUTTON --- */
.Play-btn {
  margin-top: 1rem;
  cursor: pointer;
  font-family: var(--super-std);
  font-size: var(--font-sz-md);
  color: var(--color-cloud-white);
  background-color: var(--color-transparent-pink);
  text-align: center;
  height: 5rem;
  width: 10rem;
  border-radius: 50px;
  border: 3px solid var(--color-pink);
  text-decoration: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: color 0.3s;
}
.Play-btn:hover {
  color: var(--color-blue);
}

.Play-btn-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Play-btn-mobile {
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  margin-top: -1rem;
}
/* #endregion --- PLAY BUTTON --- */

/* #region --- LOGO ANIMATION --- */
@media (prefers-reduced-motion: no-preference) {
  .Landing-logo {
    animation: fade-in 1s ease-in;
  }
  .Learn {
    opacity: 0;
    animation: fade-in 1s ease-in forwards;
    animation-delay: 1s;
  }
  .Play-btn {
    opacity: 0;
    animation: fade-in 1s ease-in forwards;
    animation-delay: 2s;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* #endregion --- LOGO ANIMATION --- */

/* #region --- MOBILE VIEW --- */
@media (max-width: 35em) {
  .Landing-logo {
    height: 25vmin;
    padding-top: 15vh;
  }
  /* .Play-btn {
    height: 8rem;
    width: 8rem;
    border-radius: 50%;
    border: none;
    background-color: transparent;
  } */
  .Cloud-background {
    background-size: 30rem;
  }
}
@media (min-width: 40em) and (max-width: 57em) {
  .Landing-logo {
    height: 25vmin;
    padding-top: 15vh;
  }
  .Play-btn {
    height: 4rem;
    width: 8rem;
    font-size: 40px;
    border: 2.5px solid var(--color-pink);
    text-decoration: none;
  }

}
/* #endregion --- MOBILE VIEW --- */