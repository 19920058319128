/* #region --- INPUT STYLING --- */

input[type="text"],
input[type="email"] {
  font-family: var(--circular-std);
  padding: 10px;
  border: 3px solid var(--color-blue);
  border-radius: 5px;
  background-color: var();
  background-color: var(--color-cloud-blue);
}

textarea {
  font-family: var(--circular-std);
  padding: 10px;
  border: 3px solid var(--color-blue);
  min-height: 4rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  resize: none;
  background-color: var(--color-cloud-blue);
}

/* styling for when selected */
input[type="text"]:focus,
input[type="email"]:focus {
  border-color: var(--color-purple); 
}

textarea:focus {
  border-color: var(--color-purple); 
}

/* #endregion --- INPUT STYLING --- */

.contact-page {
  position: fixed;
  height: 50vh;
  width: 100vw;
  display: flex;
  /* overflow: hidden; */
}

h1 {
  margin-top: 1rem;
  font-family: var(--letters-std);
  font-size: 50px;
  color: var(--color-light-brown);
}

.mail {
  height: 50px;
  margin-left: 5px;
  margin-top: 1rem
}

.form-box {
  width: 100%;
  max-width: 40rem;
  height: fit-content;
 }

.form-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  background-color: var(--color-transparent-cloud-blue);
  padding: 2rem 3rem;
  border-radius: 30px;
  margin: auto;

  position: relative;
  height: fit-content;
}

/* #region --- THANK YOU PAGE --- */ 
.thank-you {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 0;
}

.thank-you h2 {
  font-family: var(--letters-std);
  font-size: var(--font-sz-md);
  text-align: center;
  color: var(--color-light-ivory);
  background: rgba(167, 150, 255, 0.7);
  border: 3px solid var(--color-purple);
  width: 30rem;
  border-radius: 50px;
  margin: 0;
  padding: 2rem;
}

.group-photo {
  height: 20rem;
  margin: 0;
}

.ty-next {
  height: 2rem;
}

.close-position {
  position: absolute;
  top: -10px; 
  right: -30px;

  cursor: pointer;

  background: transparent!important;
  border: none!important;
}
/* #region --- THANK YOU PAGE --- */ 

/* #region --- SUBMIT BUTTON --- */
.submit-btn {
  margin-top: 1rem;
  padding:  0rem 1rem;
  cursor: pointer;
  font-family: var(--super-std);
  font-size: var(--font-sz-sm);
  color: var(--color-light-ivory);
  background-color: var(--color-transparent-pink);
  text-align: center;
  height: 3.5rem;
  width: fit-content;
  border-radius: 50px;
  border: 3px solid var(--color-pink);
  transition: 0.3s;
}
.submit-btn:hover {
  background-color: var(--color-pink);
}
/* #endregion --- SUBMIT BUTTON --- */

/* #region --- LOADING BUTTON --- */
.loader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#FFFFF9 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}
/* #endregion --- LOADING BUTTON --- */

/* #region --- MEDIA QUERIES --- */
@media (min-width: 36em) and (max-width: 48em) {
  .form-box {
    max-width: 25rem;
   }

  .group-photo {
    height: 15rem;
   }

  .thank-you h2 {
    font-size: 40px;
    width: 20rem;
  }
}
@media (max-width: 35em) {
  .form-box {
    max-width: 15rem;
   }
   h1 {
    font-size: 30px;
   }
   .mail {
    height: 30px;
    margin-left: 7px;
  }
  .submit-btn {
    border: 2.5px solid var(--color-pink);
    height: 2.5rem;
    width: 6rem;
    font-size: 20px;
  }
  

  .group-photo {
  height: 10rem;
  }
  .thank-you h2 {
    font-size: 28px;
    width: 14rem;
  }
  
}
/* #endregion --- MEDIA QUERIES --- */