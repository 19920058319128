.about-card {
  background-color: var(--color-transparent-cloud-blue);
  padding: 1rem .5rem;
  border-radius: 30px;
  flex: 1;
  max-width: 20rem;
}

.avatar {
  margin-top: 1rem;
  width: 120px;
  height: 120px;
  border-radius: 50%; 
  object-fit: cover; 
  border: 5px solid var(--color-light-ivory);
}

.about-card h2 {
  margin-top: 1rem;
  margin-bottom: .5rem;
  font-family: var(--letters-lined);
  font-size: var(--font-sz-md);
  font-weight: 400;
  color: var(--color-med-brown);
  transition: color 0.3s;
}
.about-card h2:hover {
  color: var(--color-purple);
}

.about-card h3 {
  margin-top: 1rem;
}

.about-card p {
  margin-top: 1rem;
  color: var(--color-light-brown);
  font-size: 18px;
  background-color: var(--color-transparent-cloud-blue);
  border-radius: 10px;
  padding: .5rem;
}

.web-link {
  font-family: var(--letters-std);
  font-size: 30px;
  color: var(--color-blue);
  text-decoration: none;
  border-bottom: 1px solid var(--color-blue);
  transition: color 0.3s;
}
.web-link:hover {
  color: var(--color-transparent-blue);
}

.spec-logo {
  background-color: var(--color-transparent-blue);
  border-radius: 50px;
  padding: 5px;
  height: 2.5rem;
}

.lighten-on-hover {
  filter: opacity(75%);
  transition: filter 0.3s ease-in-out;
}

.lighten-on-hover:hover {
  filter: none;
}

/* #region --- MEDIA QUERIES --- */
@media (max-width: 35em) {
  .about-card {
    justify-content: center;
    max-width: 20rem!important;
  }
  .about-card p {
    max-width: 15rem!important;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 48em) {
  .about-card {
    justify-content: center;
    max-width: 25rem;
  }
  .about-card p {
    max-width: 18rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 57em) {
  .about-card {
    justify-content: center;
    max-width: 30rem;
  }
  .about-card p {
    max-width: 20rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 956px) {
  .about-card {
    justify-content: center;
    max-width: 30rem;
  }
  .about-card p {
    max-width: 20rem;
    margin-left: auto;
    margin-right: auto;
  }
}
/* #endregion --- MEDIA QUERIES --- */


/* JUMP ANIMATION */
@keyframes jump {
  0% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
  100% { transform: translateY(0); }
}

.jump {
  transition: transform 0.3s ease;
}

.jump:hover {
  animation: jump 0.6s ease;
}