.scroll-to-top {
  margin-top: 0rem;
  margin-bottom: 1.5rem;
  padding: .5rem 1.5rem;
  width: fit-content;

  font-family: var(--super-std);
  font-size: var(--font-sz-sm);
  font-weight: 400;
  color: var(--color-light-ivory);

  border-radius: 50px;
}

/* .scroll-to-top:hover {
  background-color: var(--color-pink);
} */