.p-block {
  text-align: center;
  color: var(--color-blue);
  font-style: italic;
  font-size: 20px;
  background-color: var(--color-transparent-cloud-blue);
  border-radius: 50px;
  padding: 1rem 2rem;
  margin: 1rem auto 2rem;
  width: fit-content;
}

.game-link {
  text-decoration: none;
  color: var(--color-light-ivory);
  transition: color 0.3s;
}
.game-link:hover {
  color: var(--color-yellow);
}


.sv-team {
  margin-top: 5px;
  font-size: 25px;
}

/* #region --- MEDIA QUERIES --- */
@media (max-width: 48em) {
  .mobile-view {
    text-align: center;
  }
  .mobile-error {
    text-align: center;
  }
  .flex {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 48em) {
  .flex {
    display: flex;
    justify-content: center;
  }
}
/* #endregion --- MEDIA QUERIES --- */