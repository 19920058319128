body {
  overflow-x: hidden;
}

.header-bg {
  position: absolute;
  top: 0;
  right: 0;
  background-image: url(../assets/game_pngs/MapEnd.png);
  background-position: center;
  background-size: 50rem;
  width: 100%;
  height: 100%;
  background-repeat: repeat-x;
  z-index: -1;
}

.primary-header {
  position: relative;
  height: 10rem;
  padding-top: 2rem;
  justify-content: space-between;
  z-index: 5;
}

.mobile-nav-toggle {
  display: none;
}

.close-button {
  background: transparent!important;
  border: none!important;
}

.display-flex {
  display: flex;
  gap: var(--gap, 2rem);
}

.sr-only {
  display: none;
}

.navigation {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 2rem;
  margin-top: .5rem;
}

.link {
  background-color: var(--color-transparent-purple);
  border: 2.5px solid var(--color-purple);
  border-radius: 50px;

  text-decoration: none;
  font-family: var(--super-std);
  font-size: 20px;
  padding: .5rem 1rem;

  color: var(--color-light-ivory);

  transition: background-color 0.3s ease;
}
.link:hover {
  background-color: var(--color-purple);
}

.logo-fade {
  opacity: 1; 
  transition: opacity 0.3s ease;
}
.logo-fade:hover {
  opacity: 0.5;
}
.sv-logo {
  width: 250px;
  margin-left: 2rem;
  margin-top: -1rem;
}

/* #region --- SCALLOP BORDER --- */
.scallop {
  position:relative;
  background: var(--color-light-ivory);
  padding:10px;
}
.scallop::after { 
  position:absolute;
  bottom: -50px;
  left: 0px;
  width: 100%;
  content:" ";
  background: 
  radial-gradient(circle at 50% 0%, var(--color-light-ivory) 25%, #5EA2ED 26%, #5EA2ED 40%);
  background-color: var(--color-light-ivory);
  background-size: 50px 100px;
  height:50px;
  background-repeat: repeat-x;
}
/* #endregion --- SCALLOP BORDER --- */

/* #region --- MEDIA QUERIES --- */
@media (max-width: 48em) {
  .primary-header .sv-logo {
    margin-left: 0rem;
    /* width: 250px; */
  }

  .navigation {
    --gap: 2rem;
    position: fixed;
    margin: .5rem;

    inset: 0 0 0 30%;
    flex-direction: column;
    padding: 2rem 2rem;
    align-items: center;
    justify-content: center;
    height: 20vh;
    left: 0rem;
    right: 0rem;

    background: var(--color-transparent-cloud-blue);
    backdrop-filter: blur(1rem);
    border: 7px solid var(--color-pink);
    border-radius: 50px;

    transform: translateX(150%);
    z-index: 500;
  }

  .navigation.visible {
    transform: translateX(0%);
  }

  .mobile-nav-toggle {
    display: block;
    position: absolute;
    top: 2rem;
    right: 1rem;

    font-family: var(--super-std);
    font-size: var(--font-sz-sm);
    color: var(--color-light-ivory);
    background: var(--color-transparent-pink);
    padding: .2rem 1rem;
    border: 2.5px solid var(--color-pink);
    border-radius: 50px;

    z-index: 999;
  }

}
/* #endregion --- MEDIA QUERIES --- */