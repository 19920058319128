.oops-container {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-view {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.oops {
  font-family: var(--super-std);
  font-size: var(--font-sz-lg);
  margin: -100px;
  color: var(--color-purple);
  font-weight: 400;
}

.home-link {
  text-decoration: none;
  margin-top: -6rem;
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem;
  width: fit-content;
  font-family: var(--super-std);
  font-size: var(--font-sz-sm);
  font-weight: 400;
  color: var(--color-light-ivory);
  border-radius: 50px;
}

.mobile-error {
  color: var(--color-light-brown);
  background-color: var(--color-transparent-cloud-blue);
  border-radius: 50px;
  padding: 0.75rem;
  width: 15rem;
  margin: 110px auto;
}
